/* eslint-disable consistent-return */
import axios from 'axios';
import URIs from 'consts/URIs';
import { IPositionCompanyCardDto } from 'interfaces/_v2/positionJd/IPositionCompanyCardListRs';
import IPositionDetailRs, { IPositionAccStatusRs, IPositionApplyProfileComplete, IPositionDetailAdditionalDto, IPositionDetailBasicDto, IPositionDetailCompanyDto, IPositionDetailJobPostingDto, IPositionDetailStateDto, IPositionExternalDetailDto } from 'interfaces/_v2/positionJd/IPositionDetailRs';
import { IPositionJobCardDto } from 'interfaces/_v2/positionJd/IPositionJobListCardRs';
import { IRequiredItems } from 'interfaces/_v2/positionJd/IProfileRequiredItemRs';
import { IResumeAdditionInfoApplyDto, IResumeAdditionInfoDto, IResumeAdditionInfoRs, IResumeAdditionInfoTemp, IResumeAdditionInfoTempDto } from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import INavigationRecommendPositionListRs from 'interfaces/rqrs/INavigationRecommendPositionListRs';
import { IPositionMatchingJobCardDto } from 'interfaces/rqrs/IPositionMatchingJobListRs';
import { action, observable } from 'mobx';
import { TimeType } from 'utils/DateUtils';
import request from 'utils/request';

const defaultPageSize = 12;

export enum MATCH_BOOKMARK_TYPE {
  ERROR = 'ERROR',
  CHECK = 'CHECK',
  UNCHECK = 'UNCHECK',
}

export default class PositionJdModel {
    @observable positionSn: number = 0;
    @observable positionDetailJd: IPositionDetailRs | null = null;
    @observable additionalInfo: IPositionDetailAdditionalDto | null = null;
    @observable basicInfo: IPositionDetailBasicDto | null = null;
    @observable companyInfo: IPositionDetailCompanyDto | null = null;
    @observable jobPosting: IPositionDetailJobPostingDto | null = null;
    @observable stateInfo: IPositionDetailStateDto | null = null;
    @observable externalInfo: IPositionExternalDetailDto | null = null;
    @observable profileComplete: IPositionApplyProfileComplete | null = null;
    @observable navigationTotalList: IPositionMatchingJobCardDto[] | null = null;
    @observable navigationRecommendList: INavigationRecommendPositionListRs | null = null;
    @observable anchoringPage: number | null = null;
    @observable toast: { isOpen: boolean, type: MATCH_BOOKMARK_TYPE } = { isOpen: false, type: MATCH_BOOKMARK_TYPE.ERROR };

    @observable positionMatchingCompanyList: IPositionCompanyCardDto[] | null = null;
    @observable positionMatchingJobList: IPositionJobCardDto[] | null = null;
    @observable positionMatchingChallengeList: IPositionCompanyCardDto[] | null = null;
    @observable locationX: string = '';
    @observable locationY: string = '';
    @observable challengeTimeType: TimeType = TimeType.BEFORE;
    @observable positionScrollOffSet = 0;
    @observable profileRequiredItems: IRequiredItems | null = null;
    @observable profileAdditionalInfo: IResumeAdditionInfoDto[] | null = null;
    @observable tempAdditionalInfo: IResumeAdditionInfoTemp | null = null;
    @observable additionInfoValid: boolean = false;
    @observable isDirty: boolean = false;
    @observable positionAccStatus : IPositionAccStatusRs | null = null;

    @action
    init = async (sn: number) => {
      this.positionSn = sn;
      this.positionDetailJd = null;
      this.additionalInfo = null;
      this.basicInfo = null;
      this.companyInfo = null;
      this.stateInfo = null;
      this.jobPosting = null;
      this.externalInfo = null;
      this.navigationTotalList = null;
      this.navigationRecommendList = null;
      this.anchoringPage = null;
      this.toast = { isOpen: false, type: MATCH_BOOKMARK_TYPE.ERROR };
      this.profileComplete = null;
      this.positionMatchingCompanyList = null;
      this.positionMatchingJobList = null;
      this.positionMatchingChallengeList = null;
    }

    @action
    terminate = () => {
      this.positionSn = 0;
      this.positionDetailJd = null;
      this.additionalInfo = null;
      this.basicInfo = null;
      this.companyInfo = null;
      this.stateInfo = null;
      this.jobPosting = null;
      this.externalInfo = null;
      this.navigationTotalList = null;
      this.navigationRecommendList = null;
      this.anchoringPage = null;
      this.toast = { isOpen: false, type: MATCH_BOOKMARK_TYPE.ERROR };
      this.profileComplete = null;
      this.positionMatchingCompanyList = null;
      this.positionMatchingJobList = null;
      this.positionMatchingChallengeList = null;
    }

    @action
    loadPositionDetail = async (positionSn: number) => {
      try {
        this.positionDetailJd = (await request<IPositionDetailRs>({
          method: 'get',
          url: URIs.get_positions_sn(positionSn),
        }));
        this.additionalInfo = this.positionDetailJd.additionalInfo;
        this.basicInfo = this.positionDetailJd.basicInfo;
        this.companyInfo = this.positionDetailJd.companyInfo;
        this.stateInfo = this.positionDetailJd.stateInfo;
        this.externalInfo = this.positionDetailJd.externalInfo;
        this.jobPosting = this.positionDetailJd.jobPosting;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadPositionPreviewDetail = async (publicKey: string, positionId: string) => {
      try {
        this.positionDetailJd = (await request<IPositionDetailRs>({
          method: 'get',
          url: URIs.get_match_positions_prieview,
          params: { positionId, publicKey },
        }));
        this.additionalInfo = this.positionDetailJd.additionalInfo;
        this.basicInfo = this.positionDetailJd.basicInfo;
        this.companyInfo = this.positionDetailJd.companyInfo;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    verifyApplyPosition = async () => {
      try {
        const res = (await request({
          method: 'post',
          url: URIs.verify_match_application_position_apply(this.positionSn),
        }));
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    deleteApplyPosition = async () => {
      try {
        const res = (await request({
          method: 'delete',
          url: URIs.delete_match_application_position_apply(this.positionSn),
        }));
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadProfileComplete = async () => {
      try {
        this.profileComplete = await request<IPositionApplyProfileComplete>({
          method: 'get',
          url: URIs.get_profiles_v2_complete,
        });
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadNavigationTotalList = async () => {
      try {
        this.navigationTotalList = (await request<{positions: IPositionMatchingJobCardDto[]}>({
          method: 'get',
          url: URIs.get_match_position_navigation_total(this.positionSn),
        })).positions;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadNavigationRecommendList = async (page?: number) => {
      try {
        this.navigationRecommendList = (await request({
          method: 'get',
          url: URIs.get_match_position_navigation_recommend,
          params: { page, size: 3 },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadNavigationRecommendAnchoring = async () => {
      try {
        this.anchoringPage = (await request<{page: number}>({
          method: 'get',
          url: URIs.get_match_position_anchoring(this.positionSn),
          params: { pageSize: 3 },
        })).page;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadMatchingCompanyList = async (positionSn: number) => {
      try {
        this.positionMatchingCompanyList = (await request<{positions: IPositionCompanyCardDto[]}>({
          method: 'get',
          url: URIs.get_position_matching_company(positionSn),
        })).positions;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadMatchingChallengeList = async (positionSn: number) => {
      try {
        this.positionMatchingChallengeList = (await request<{positions: IPositionCompanyCardDto[]}>({
          method: 'get',
          url: URIs.get_position_matching_challenge(positionSn),
        })).positions;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadMatchingJobList = async (positionSn: number) => {
      try {
        this.positionMatchingJobList = (await request<{positions: IPositionJobCardDto[]}>({
          method: 'get',
          url: URIs.get_position_matching_job(positionSn),
          params: { pageSize: defaultPageSize },
        })).positions;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    saveBookMark = async () => {
      try {
        const { firstBookmarkYn } = (await request<{ firstBookmarkYn: boolean }>({
          method: 'put',
          url: URIs.put_match_positions_bookmark(this.positionSn),
        }));
        return firstBookmarkYn;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    deleteBookMark = async () => {
      try {
        const res = (await request({
          method: 'delete',
          url: URIs.delete_match_positions_bookmark(this.positionSn),
        }));
        return res;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    getLocation = async () => {
      try {
        await axios.get(`https://dapi.kakao.com/v2/local/search/address.json?query=${this.basicInfo?.address}`,
          { headers: { Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_KEY}` } })
          .then((res) => {
            const location = res.data.documents[0];
            this.locationX = location.address.x;
            this.locationY = location.address.y;
          });
      } catch (e) {
        console.error(e);
      }
    };

    @action
    getLocationPreview = async (previewAddress:string) => {
      try {
        await axios.get(`https://dapi.kakao.com/v2/local/search/address.json?query=${previewAddress}`,
          { headers: { Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_KEY}` } })
          .then((res) => {
            const location = res.data.documents[0];
            this.locationX = location.address.x;
            this.locationY = location.address.y;
          });
      } catch (e) {
        console.error(e);
      }
    };

  @action
  getRequirementVerification = async (positionSn: number) => {
    try {
      this.profileRequiredItems = (await request<{ requiredItems: IRequiredItems }>({
        method: 'get',
        url: URIs.verify_match_application_position_requirement(positionSn),
      })).requiredItems;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  applyPosition = async (positionSn: number, additionInfo: IResumeAdditionInfoApplyDto[]) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_match_application_position_apply(positionSn),
        data: { additionInfo },
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  getProfileAdditionInfo = async (positionSn: number) => {
    try {
      this.profileAdditionalInfo = (await request<IResumeAdditionInfoRs>({
        method: 'get',
        url: URIs.get_match_application_position_addition_info(positionSn),
      })).additionInfo;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  putProfileAdditionInfo = async (positionSn:number, additionInfo:IResumeAdditionInfoApplyDto[]) => {
    try {
      const res = request({
        method: 'put',
        url: URIs.put_match_application_position_addition_info(positionSn),
        data: { additionInfo },
      });
      return res;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getPositionAccStatus = async (positionSn: number) => {
    try {
      this.positionAccStatus = (await request<IPositionAccStatusRs>({
        method: 'get',
        url: URIs.get_match_application_position_acc_status(positionSn),
      }));
    } catch (e) {
      console.error(e);
    }
  }

  @action
  setIsDirty = (isDirty: boolean) => {
    this.isDirty = isDirty;
  }
}
